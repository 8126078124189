import { useNavigate } from "react-router-dom";
import { PAGE_SINGLE_ARTICLE } from "../../PagesNames";
import { Language } from "../../language/English"
import { ArticlePojo } from "../../services/Static/ArticlePojo/ArticlePojo";
import { ARTICLE_STATUS_ACTIVE, ARTICLE_STATUS_DELETE, ARTICLE_STATUS_INACTIVE } from "../../services/Static/ArticleConstant";
import { CButton, CCol } from "@coreui/react";
import ConfirmationBoxModal from "../../components/Layout/ConfirmationBoxModal";
import Notification from "../../services/Notification/Notification";
import { ArticleService } from "../../services/Static/ArticleService";
import { useEffect, useState } from "react";
import Logger from "../../services/Logger/Logger";
import LazyLoad from "react-lazyload";
import Loader from "../../components/Layout/Loader";
import ReadMoreText from "../ReadMoreText/ReadMoreText";
const ArticleList = (props : {pages : ArticlePojo, key : number, count : number}) => {
    const pages = props.pages;
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const notification = new Notification();
    const logger = new Logger();
    const navigate = useNavigate();
    const [showAction, setShowAction] = useState<React.ReactElement | null>(null);
    const [articleStatus, setArticleStatus] = useState<number>();
    const [deleteConfirmationBoxShow, setDeleteConfirmationBoxShow] = useState(false);
    const [activeConfirmationBoxShow, setActiveConfirmationBoxShow] = useState(false);
    const [inactiveConfirmationBoxShow, setInactiveConfirmationBoxShow] = useState(false);

    const handleClick = (articleId : number) => {
        const link = PAGE_SINGLE_ARTICLE + '/' + articleId + '/edit';
        navigate(link);
    }

    const handleActiveConfirmation = () =>
    {
        setActiveConfirmationBoxShow(!activeConfirmationBoxShow);
    }

    const handleInactiveConfirmation = () =>
    {
        setInactiveConfirmationBoxShow(!inactiveConfirmationBoxShow);
    }

    const deleteHandleConfirmation = () =>
    {
        setDeleteConfirmationBoxShow(!deleteConfirmationBoxShow);
    }
    const deleteData = (appointmentId: number) =>
    {
        changeStatusClick(appointmentId, ARTICLE_STATUS_DELETE);
    }
    const activeData = (appointmentId: number) =>
    {
        changeStatusClick(appointmentId, ARTICLE_STATUS_ACTIVE);
    }

    const inactiveData = (appointmentId: number) =>
    {
        changeStatusClick(appointmentId, ARTICLE_STATUS_INACTIVE);
    }

    const handleStatus = (status: number) =>
    {
        let text = '';
        let className = '';
        if(status === ARTICLE_STATUS_ACTIVE)
        {
            text = Language.ACTIVE;
            className = 'article-label';
        }else if (status === ARTICLE_STATUS_INACTIVE) {
            text = Language.DISABLED;
            className = 'article-label disable';
        }else if (status === ARTICLE_STATUS_DELETE) {
            text = Language.DELETED;
            className = 'article-label deleted';
        }
        setShowAction(<div className={className}>{text}</div>);
        setArticleStatus(status);
    }
    
    const changeStatusClick = async(appointmentId: number, status: number) =>
    {
        setShowLoader(true);
        try{
            const articleService = new ArticleService();
            let response: any;
            response = await articleService.changeStatus(appointmentId, status);
            if(response){
                if (status === ARTICLE_STATUS_DELETE) {
                    notification.success(Language.DELETED);
                } else if(status === ARTICLE_STATUS_ACTIVE) {
                    setActiveConfirmationBoxShow(!activeConfirmationBoxShow);
                    notification.success(Language.ACTIVATED);
                } else if(status === ARTICLE_STATUS_INACTIVE) {
                    setInactiveConfirmationBoxShow(!inactiveConfirmationBoxShow);
                    notification.success(Language.DISABLED);
                }
                handleStatus(status);
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            logger.error(e);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        handleStatus(pages.status);
    }, [pages.status]);

    return (
        <>
            { showLoader ? <Loader /> : null }
            <CCol md={4} className="mt-2">
                <div className={`card`}  id={`imageId-1`}>
                        {
                            articleStatus !== ARTICLE_STATUS_DELETE ? 
                            <><div className="close-button" onClick={() => deleteHandleConfirmation()}>
                            <span>X</span>
                            {
                                deleteConfirmationBoxShow ? 
                                <ConfirmationBoxModal 
                                    title={pages.title} 
                                    message={Language.DELETE_CONFIRMATION} 
                                    buttonOneText={Language.YES} 
                                    buttonTwoText={Language.CLOSE} 
                                    buttonOneCallable={deleteData} 
                                    buttonTwoCallable={deleteHandleConfirmation} 
                                    buttonOneCallBackFunctionParams={pages.articleId}
                                    buttonTwoCallBackFunctionParams = {null} />
                                : null
                            }
                            </div>
                            </>:<></>
                        }
                    <div className="done-mark">
                        {showAction}
                    </div>
                    <LazyLoad height={200} offset={100}>
                        <img src={pages.thumbnailPath} className="card-img-top" alt={pages.title} />
                    </LazyLoad>
                    <div className={`card-body images`}>
                        <CCol md={9} className="card-images col-9">
                            {
                                articleStatus === ARTICLE_STATUS_ACTIVE ? 
                                <>
                                    <CButton className="show-action" size="sm"  color="dark" onClick={() => handleInactiveConfirmation()}>
                                        {Language.DISABLE}
                                    </CButton>                               
                                    {
                                        inactiveConfirmationBoxShow ? 
                                        <ConfirmationBoxModal 
                                            title={pages.title} 
                                            message={Language.DISABLED_CONFIRMATION} 
                                            buttonOneText={Language.YES} 
                                            buttonTwoText={Language.CLOSE} 
                                            buttonOneCallable={inactiveData} 
                                            buttonTwoCallable={handleInactiveConfirmation} 
                                            buttonOneCallBackFunctionParams={pages.articleId}
                                            buttonTwoCallBackFunctionParams = {null} />
                                        : null
                                    }
                                </>
                                : <>
                                    <CButton className="show-action" size="sm"  color="success" onClick={() => handleActiveConfirmation()}>
                                        {Language.ACTIVATE}
                                    </CButton>                               
                                    {
                                        activeConfirmationBoxShow ? 
                                        <ConfirmationBoxModal 
                                            title={pages.title} 
                                            message={Language.ACTIVE_CONFIRMATION} 
                                            buttonOneText={Language.YES} 
                                            buttonTwoText={Language.CLOSE} 
                                            buttonOneCallable={activeData} 
                                            buttonTwoCallable={handleActiveConfirmation} 
                                            buttonOneCallBackFunctionParams={pages.articleId}
                                            buttonTwoCallBackFunctionParams = {null} />
                                        : null
                                    }
                                </>
                            }
                        </CCol>
                        <CCol md={3} className="card-images col-3">
                            <CButton className="btn image-edit-btn" color="dark" onClick={() => handleClick(pages.articleId)}>
                                {Language.EDIT}
                            </CButton>
                        </CCol>                      
                    </div>
                    <CCol md={12} className="card-images col-12 business-detail-box">
                        <div><b>
                            {
                                pages.title ? <ReadMoreText text={pages.title} maxLength={60} /> : 'N/A'
                            }
                        </b></div>
                        <div>
                            {
                                pages.shortDescription ? <ReadMoreText text={pages.shortDescription} maxLength={100} /> : 'N/A'
                            }
                        </div>
                    </CCol>
                </div>
            </CCol>
        </>
    )
}
export default ArticleList;