import { Layout } from "../../components/Layout";
import { useEffect, useState } from "react";
import { ContactService } from "../../services/Contact/ContactService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import { ContactPojo } from "../../services/Contact/ContactPojo/ContactPojo";
import AllContact from "./AllContact";
import IndividualContact from "./IndividualContact";

const Contact = () => {
    const [contacts, setContacts] = useState<ContactPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    
    useEffect(() => {
        const notification = new Notification();
        const logger = new Logger();
        const fetchContact = async() => {
            try{
                const contactService = new ContactService();
                const response = await contactService.getAll();
                if(response){
                    setContacts(response.contacts);
                } else {
                    notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
                }
                setShowLoader(false);
            } catch (e){
                notification.error(Language.SOMETHING_IS_WRONG);
                setShowLoader(false);
                logger.error(e);
            }
        }
        setShowLoader(true);
        fetchContact();
    }, []);
    return (
        <div>
            <Layout>
                { showLoader ? <Loader /> : null }
                <AllContact>
                {
                    contacts.length > 0 && contacts.map( (contacts, index) => (
                        <IndividualContact contacts={contacts} key={contacts.contactId} count={index}/>
                    ))
                }
                </AllContact>
        </Layout>
      </div>
    )
}
export default Contact;