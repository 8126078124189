import { Language } from "../../language/English";
import { CCol, CForm, CContainer, CRow, CFormInput, CFormTextarea, CButton, CFormSelect } from "@coreui/react"
import { ArticlePojo } from "../../services/Static/ArticlePojo/ArticlePojo";
import JoditEditor from "jodit-react";
import {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { ArticleValidation } from "../../services/Static/ArticleValidation";
import Notification from "../../services/Notification/Notification";
import Loader from "../../components/Layout/Loader";
import UrlService from "../../services/Url/UrlService";
import Logger from "../../services/Logger/Logger";
import {API_EDITOR_FILE_UPLOAD, LANGUAGE_ENGLISH, LANGUAGE_GERMAN, URL_TYPE_ARTICLE, URL_TYPE_ID_ARTICLE } from "../../Constant";
import { PAGE_ARTICLES, PAGE_LOGIN } from "../../PagesNames";
import { useNavigate } from "react-router-dom";
import ArticleImageCard from "./ArticleImageCard";
import axios from "axios";
import { API_ADD_ARTICLE, API_UPDATE_ARTICLE } from "../../services/Static/ArticleConstant";
import { logout } from "../../features/authSlice";

const SingleArticleForm = (props : {article? : ArticlePojo}) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const article = props.article;
    const articleId = article?.articleId;
    const [folderName, setFolderName] = useState<string>(article?.folderName || "");
    const notification = useMemo(() => new Notification(), []);
    const logger = useMemo(() => new Logger(), []);
    const urlService = useMemo(() => new UrlService(), []);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [existingUrl, setExistingUrl] = useState('');
    const [ url, setUrl] = useState<string>();
    const [ showUrlExist, setShowUrlExist ] = useState<boolean>(false);
    const [selectLanguage, setSelectLanguage] = useState<number>(0);
    const [thumbnail, setThumbnail] = useState("");
    const [preThumbnail, setPreThumbnail] = useState("");
    const [Editorconfig, setEditorconfig] = useState({});
    const navigate = useNavigate();
    const editor = useRef(null);

    const formValidation = ArticleValidation(article); 
    const {register, watch, handleSubmit, setValue, formState : {errors}} = useForm<ArticlePojo>({
        resolver : yupResolver(formValidation)
    });

    const config = {
        headers: { Authorization: `Bearer ${user.token}` }
    };

    const doesUrlExist = useCallback(async () :Promise<void> => {
        try 
        {
            if(url)
            {
                if(existingUrl !== url)
                {
                    if(url !== undefined && url !== ''){
                        const result = await urlService.doseUrlExist(url, URL_TYPE_ARTICLE);
                        if(result.isSuccess === true){
                            if(result.typeReferenceId != null)
                            {
                                if(articleId !== undefined && result.typeReferenceId === articleId && result.typeId === URL_TYPE_ID_ARTICLE){
                                    setShowUrlExist(false);
                                } else {
                                    setShowUrlExist(true);
                                }
                            }
                            else
                            {
                                setShowUrlExist(false);
                            }
                        } else {
                            setShowUrlExist(false);
                        }
                    }
                }
                else
                {
                    setShowUrlExist(false);
                }
            }
            
            
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [url, existingUrl, urlService, articleId, notification, logger]);

    const handleInputChange = (url : string) => { 
        setUrl(url);
        doesUrlExist();
      };

      const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (!e.target.files) {
                return;
            }
            const image = e.target.files;
            const details = {
                imageUrl : URL.createObjectURL(image[0]),
                file : image,
            }
            setThumbnail(details.imageUrl);
            setPreThumbnail(details.imageUrl);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const onSubmit: SubmitHandler<ArticlePojo> = async(data) :Promise<void> => {
        try {
            
            if(showUrlExist){
                notification.error(Language.PLEASE_USE_ANOTHER_URL)
                return;
            }

            if(data.url.indexOf(' ') > 0){
                notification.error(Language.NO_SPACED_ALLOWED_IN_URL);
                return;
            }

            setShowLoader(true);
            const formData = new FormData();
            
            formData.append('language', String(data.language));
            formData.append('thumbnail', data.thumbnail[0]);
            formData.append('title', data.title);
            formData.append('url', data.url);
            formData.append('metaTitle', data.metaTitle);
            formData.append('metaKeyword', data.metaKeyword);
            formData.append('metaDescription', data.metaDescription);
            formData.append('shortDescription', data.shortDescription);
            formData.append('description', data.description);
            formData.append('preImage', thumbnail);
            formData.append('folderName', folderName);
            let basePath = "";
            let successMessage = Language.ARTICLE_CREATED_SUCCESSFULLY;
            let failMessage = Language.ARTICLE_NOT_CREATED_TECHNICAL_ERROR;
            if(article === undefined){
                basePath = API_ADD_ARTICLE;
            } else {
                formData.append('articleId', String(articleId));
                basePath = API_UPDATE_ARTICLE;
                successMessage = Language.ARTICLE_UPDATED_SUCCESSFULLY;
                failMessage = Language.ARTICLE_NOT_UPDATED_TECHNICAL_ERROR;
            }

            axios.post(basePath, formData,
                config
              )
              .then(function (response) {
                // handle success
                if(response.status === 200 && response.data.isSuccess === true)
                {
                    setShowLoader(false);
                    notification.success(successMessage);
                    navigate(PAGE_ARTICLES);
                } else if(response.data.isSuccess === false && response.data.messageForUser) {
                    setShowLoader(false);
                    notification.error(response.data.messageForUser);
                } else {
                    setShowLoader(false);
                    notification.error(failMessage);
                }
              })
              .catch(function (error) {
                // handle error
                if(error.response.status === 401 || error.response.statusText === "Unauthorized"){
                  dispatch(logout());
                  notification.error("Token is Expired");
                  navigate(PAGE_LOGIN);
                }
              })
           
            setShowLoader(false);

        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const handleLanguageChange = (e: { target: { value: any; }; }) => {
        const selectedLanguage = e.target.value;
        setSelectLanguage(selectedLanguage);
        setValue('language', selectedLanguage);
    };

    useEffect(() => {
        setSelectLanguage(article?.language || 0);
        setValue('language', article?.language || 0);
        setValue('title', article?.title || "");
        setValue('url', article?.url || "");
        setValue('metaTitle', article?.metaTitle || "");
        setValue('metaDescription', article?.metaDescription || "");
        setValue('shortDescription', article?.shortDescription || "");
        setValue('metaKeyword', article?.metaKeyword || "");
        const initialContent = article?.description || "";
        setValue('description', initialContent);
        setExistingUrl(article?.url || "");
        setThumbnail(article?.thumbnail || "");
        setPreThumbnail(article?.thumbnailPath || "");
        setFolderName(article?.folderName || "");

    }, [article, setValue]);

    useEffect(() => {
        const JoditEditorConfig = {
            height: 1000,
            placeholder: "Start typings...",
            readonly: false,
            askBeforePasteHTML: false,
            addNewLineOnDBLClick: false,
            enableDragAndDropFileToEditor: true,  
            uploader: {
                url: API_EDITOR_FILE_UPLOAD,
                insertImageAsBase64URI: false,
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'webp'],
                headers: { Authorization: `Bearer ${user.token}` },
                filesVariableName: function (t: string) {
                  return 'files[' + t + ']';
                },
                withCredentials: false,
                pathVariableName: 'path',
                format: 'json',
                method: 'POST',
                prepareData: function (formData: any) {
                    setShowLoader(true);
                    formData.append('folderName', folderName);
                    return formData;
                },
                isSuccess: function (e: any) {
                  return e && e.response;
                },
                process: function (resp: any) {
                    return {
                        files: resp.response,
                    };
                },
                error: function (this: any, e: Error) { 
                  this.j.e.fire('errorMessage', e.message, 'error', 4000);
                  setShowLoader(false);
                },
                defaultHandlerSuccess: function (this: any, resp: any) {
                  const j = this;
                  if (resp.files && resp.files.length) {
                    const tagName = 'img';
                    resp.files.forEach((data: any) => {
                        setFolderName(data.uniqueFolderName);
                        const elm = j.createInside.element(tagName);
                        elm.setAttribute('src', data.filePath);
                        j.s.insertImage(elm as HTMLImageElement, null, j.o.imageDefaultWidth);
                    });
                  }
                  setShowLoader(false);
                },
                defaultHandlerError: function (this: any, e: { message: any; }) {
                  this.j.e.fire('errorMessage', e.message);
                  setShowLoader(false);
                },
              },
        };
        
        setEditorconfig(JoditEditorConfig);
    }, [folderName, user.token]);

    return (
        <>
        { showLoader ? <Loader /> : null }
        <CForm onSubmit={handleSubmit(onSubmit)}>
            <CContainer className="mb-5 business_container">
            <CRow>
                <CCol md={3} > <hr className="custom_hr"/> </CCol>
                <CCol md={9}></CCol>
                
                <CCol md={6} className="mb-3 mb-md-0"> 
                        <CFormSelect  label={Language.SELECT_LANGUAGE} value={selectLanguage} {...register("language")} onChange={handleLanguageChange} >
                        <option value={0} key={0}>{Language.SELECT_LANGUAGE}</option>
                        <option value={LANGUAGE_ENGLISH} key={LANGUAGE_ENGLISH}>{Language.ENGLISH}</option>
                        <option value={LANGUAGE_GERMAN} key={LANGUAGE_GERMAN}>{Language.GERMAN}</option>
                    </CFormSelect>
                    { errors.language && <div className="alert alert-danger">{errors.language.message}</div> }
                </CCol>
                <CCol md={6} className=""> 
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">{Language.UPLOAD_THUMBNAIL_IMAGE}</label>
                        <input {...register("thumbnail")} accept='image/png, image/jpg, image/jpeg, image/webp' onChange={e => handleImage(e)} className="form-control" type="file" id="formFile"/>
                        { errors.thumbnail && <div className="alert alert-danger">{errors.thumbnail.message}</div> }
                        {
                            thumbnail ? ( 
                                <ArticleImageCard imageUrl={preThumbnail}/>
                            ) : null
                        }
                    </div>
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.TITLE} {...register("title")} placeholder={Language.TYPE_HERE}/>
                    { errors.title && <div className="alert alert-danger">{errors.title.message}</div> }
                </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput {...register('url')} id="url" label={Language.URL} onBlur={ (e) => handleInputChange(e.target.value) } placeholder={Language.TYPE_HERE} text={Language.MUST_5_200_CHAR_LONG} aria-describedby="url" />
                    { errors.url && <div className="alert alert-danger">{errors.url.message}</div> }
                    { showUrlExist ? <div className="alert alert-danger">{Language.URL_ALREADY_EXIST}</div> : null }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.TITLE_SHOWN_TO_GOOGLE} {...register("metaTitle")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}/>
                    { errors.metaTitle && <div className="alert alert-danger">{errors.metaTitle.message}</div> }
                </CCol>
                <CCol md={6} className="mb-3 mb-md-4"> 
                    <CFormInput label={Language.META_KEYWORD} {...register("metaKeyword")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}/>
                    { errors.metaKeyword && <div className="alert alert-danger">{errors.metaKeyword.message}</div> }
                </CCol>
                
                <CCol md={6} className="mb-3 mb-md-0"> 
                    <CFormInput label={Language.META_DESCRIPTION} {...register("metaDescription")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}/>
                    { errors.metaDescription && <div className="alert alert-danger">{errors.metaDescription.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className="mb-3 mb-md-0"> 
                    <CFormTextarea rows={4} label={Language.SHORT_DESCRIPTION} {...register("shortDescription")} placeholder={Language.TYPE_HERE} aria-describedby="Url"  disabled={false}></CFormTextarea>
                    { errors.shortDescription && <div className="alert alert-danger">{errors.shortDescription.message}</div> }
                </CCol>
                <CCol md={12} className=""> <hr></hr> </CCol>
                <CCol md={12} className="mb-3 mb-md-0"> 
                    <strong>{Language.DESCRIPTION}</strong>
                    { errors.description && <div className="alert alert-danger">{errors.description.message}</div> }
                    <JoditEditor
                        ref={editor}
                        value={ watch("description") || "" }
                        config={Editorconfig}
                        onBlur={(newContent) => setValue("description", newContent)}
                    />
                    
                </CCol>
                <br></br>
                <CCol md={3} className=""> 
                {
                    articleId ? 
                        <CButton type="submit" className="submit_button"> { Language.UPDATE } </CButton>
                         : <CButton type="submit" className="submit_button"> { Language.CREATE_ARTICLE } </CButton>
                }
                </CCol>
                <br></br>
                </CRow>
                </CContainer>
            </CForm>
        </>
    )
}
export default SingleArticleForm;

function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}
