import { API_GET_ALL_CONTACT, API_DELETE_CONTACT, API_READ_CONTACT} from './ContactConstant';
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import ContactResultsPojo from "./ContactPojo/ContactResultsPojo";
import Validator from "../Validator/Validator";

export class ContactService{
    
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async getAll(): Promise<ContactResultsPojo>{
        try{
            const httpResult =  await this.httpService.get(API_GET_ALL_CONTACT, {});  
            return { isSuccess : httpResult.data.isSuccess, contacts : httpResult.data.response }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }    

    async delete(contactId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(contactId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.delete(API_DELETE_CONTACT, {contactId : contactId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async read(contactId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(contactId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.put(API_READ_CONTACT, {contactId : contactId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}