import { BASE_URL } from "../../Constant";

export const API_GET_ALL_ARTICLES: string = `${BASE_URL}/api/article/getAll`;
export const API_GET_ARTICLE_BY_ID: string = `${BASE_URL}/api/article/getArticleById/`;
export const API_UPDATE_ARTICLE: string = `${BASE_URL}/api/article/update`;
export const API_ADD_ARTICLE: string = `${BASE_URL}/api/article/save`;
export const API_ARTICLE_CHANGE_STATUS: string = `${BASE_URL}/api/article/changeStatus`;


export const NAME_MIN_LENGTH: number = 3;
export const NAME_MAX_LENGTH: number = 195;
export const DESCRIPTION_MIN_LENGTH: number = 10;
export const DESCRIPTION_MAX_LENGTH: number = 900;
export const INPUT_MIN_LENGTH: number = 5;
export const URL_MIN_LENGTH: number = 3;
export const URL_MAX_LENGTH: number = 190;
export const META_TITLE_MIN_LENGTH: number = 10;
export const META_TITLE_MAX_LENGTH: number = 240;
export const META_DESCRIPTION_MIN_LENGTH: number = 10;
export const META_DESCRIPTION_MAX_LENGTH: number = 950;
export const SHORT_DESCRIPTION_MAX_LENGTH: number = 950;
export const META_KEYWORD_MIN_LENGTH: number = 10;
export const META_KEYWORD_MAX_LENGTH: number = 290;

export const ARTICLE_STATUS_ACTIVE: number = 1;
export const ARTICLE_STATUS_INACTIVE: number = 2;
export const ARTICLE_STATUS_DELETE: number = 10;