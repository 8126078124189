export const Language = {
    PANEL_NAME : 'ETP-Enterprise',
    BUSINESS_TYPE : 'Business type',
    HELLO : 'Hello',
    BUSINESS_NAME : 'Business Name',
    DESCRIPTION : 'Description',
    COUNTRY_CODE : 'Country code',
    PHONE_NUMBER : 'Phone Number',
    APPOINTMENT_DATE : 'Appointment Date',
    APPOINTMENT_TIME : 'Appointment Time',
    TIME_ZONE : 'Time Zone',
    PHONE_NUMBER_REQUIRED : 'Phone number is required field',
    PHONE_NUMBER_VALID : 'Please add a valid Phone number',
    POST_CODE : 'Post code',
    COUNTRY : 'Country',
    REGION : 'Region',
    ADDRESS_LINE_1 : 'Building, Company, Apartment',
    ADDRESS_LINE_2 : 'Area, Street, Sector, Village',
    ADDRESS_LINE_3 : 'Town/City',
    ARE_MEN_ALLOWED : 'Are men allowed',
    ARE_WOMEN_ALLOWED : 'Are women allowed',
    ARE_COUPLE_ALLOWED: 'Are couple allowed',
    HAS_LICENSE : 'Has License',
    HAS_LICENSE_DESCRIPTION : 'Has any type License authorized by Govt',
    VERIFY_BUSINESS_DESCRIPTION : 'Verify Business / Give verification badge',
    VERIFICATION : 'Verification',
    URL : 'URL',
    META_TITLE : 'Meta Title',
    META_KEYWORD : 'Meta Keyword',
    META_DESCRIPTION : 'Meta Description',
    SHORT_DESCRIPTION : 'Short Description',
    ADD_BUSINESS : 'Add Business',
    BASIC_BUSINESS_DETAILS : 'Basic Business details',
    SELECT_ONE_OPTION : 'Select one option',
    TYPE_HERE : 'Type here...',
    MUST_8_20_CHAR : 'Must be 8-20 characters long.',
    MUST_100_900_CHAR : 'Must be 100-900 characters long.',
    MUST_5_12_NUM_LONG : 'Must be 5-12 numbers long.',
    MUST_3_20_CHAR_LONG : 'Must be 3-20 characters long.',
    MUST_10_250_CHAR_LONG : 'Must be 10-250 characters long.',
    MUST_3_90_CHAR_LONG : 'Must be 3-90 characters long',
    MUST_3_200_CHAR_LONG : 'Must be 3-200 characters long',
    MUST_5_200_CHAR_LONG : 'Must be 5-200 characters long',
    MUST_3_50_CHAR_LONG : 'Must be 3-50 characters long',
    MUST_10_900_CHAR_LONG : 'Must be 10-900 characters long',
    MUST_5_100_CHAR_LONG : 'Must be 5-100 characters long',
    CURRENT_STATUS : 'Current Status',
    CREATE_BUSINESS : 'Create Business',
    ACTIVE : 'Active',
    ACTIVATED : 'Activated',
    ACTIVATE : 'Activate',
    THUMBNAIL : 'Thumbnail',
    PAUSE : 'Pause',
    INACTIVE : 'Inactive',
    DISABLE : 'Disable',
    DISABLED : 'Disabled',
    DELETE : 'Delete',
    META_AND_URL_DETAILS : 'Meta & Url details',
    YES : 'Yes',
    NO : 'No',
    LICENSE_EXPIRY_DATE : 'License Expiry date',
    VERIFY_BUSINESS_LABEL : 'Verify Business / Give verification badge',
    HAS_LICENSE_LABEL : 'Has any type License authorized by Govt',
    STATUS : 'Status',
    ERROR_SELECT_A_FILE : 'Please select a image or pdf file.',
    ERROR_SELECT_JPG_OR_PDF : 'Please select a png, jpeg, webp or pdf file.',
    ERROR_FILE_SIZE : 'File size must be less than 100 mb.',
    LICENSE_VERIFICATION_DETAILS : 'License & Verification details',
    EDIT_BUSINESS : 'Edit Business',
    UPDATE_BUSINESS : 'Update Business',
    IMAGE_AND_VIDEO : 'Image & Video',
    TIMING : 'Timing',
    EMPLOYEES : 'Employees',
    LANDMARK : 'Landmark',
    MANAGE_EMPLOYEES : 'Manage Employees',
    BASIC_EMPLOYEE_DETAILS : 'Basic Employee Details',
    ADD_EMPLOYEE : 'Add Employee',
    LIST_EMPLOYEES : 'List Employees',
    FIRST_NAME : 'First Name',
    LAST_NAME : 'Last Name',
    SPECIALTY : 'Specialty',
    GENDER : 'Gender',
    MANAGE_TIMING : 'Manage Timing', 
    FROM : 'From',
    TO : 'To',
    OPENING_TIME : 'Opening Time',
    CLOSING_TIME : 'Closing Time',
    MONDAY : 'Monday',
    TUESDAY : 'Tuesday',
    WEDNESDAY : 'Wednesday',
    THURSDAY : 'Thursday',
    FRIDAY : 'Friday',
    SATURDAY : 'Saturday',
    SUNDAY : 'Sunday',
    WORKING_ON : 'Working on',
    LANDMARKS : 'Landmarks',
    MANAGE_LANDMARKS : 'Manage Landmark',
    LANDMARK_NAME : 'Landmark name',
    DISTANCE_IN_METERS : 'Distance in meters',
    ONLY_NUMBERS : 'Only numbers',
    ADD_LANDMARK : 'Add Landmark',
    MARK_AS_PRIMARY : 'Mark as primary ( Only one landmark can be primary )',
    LANDMARK_ADDED_SUCCESSFULLY : 'Landmark added successfully',
    LANDMARKS_LIST : 'Landmarks List',
    UPDATE_LANDMARK : 'Update Landmark',
    LANDMARK_UPDATED_SUCCESSFULLY : 'Landmark updated successfully',
    REVIEWS : 'Reviews',
    VIEW_IMAGE : 'View Image',
    OVERALL : 'Overall',
    REVIEW_COUNT : 'Review Count',
    REVIEW_AVERAGE : 'Review Average',
    PLEASE_ENTER_NUMERIC_VALUE : 'Please enter a numeric value',
    REVIEW_DETAILS_SUCCESSFULLY_ADDED : 'Review details successfully added',
    SAVE_REVIEW_DETAILS : 'Save Review Details',
    REVIEWER_NAME : 'Reviewer name',
    MANAGE_REVIEW : 'Manage Review',
    RATING : 'Rating',
    REVIEW_DATE : 'Review Date',
    SUBMIT : 'Submit',
    REVIEW_UPDATED_SUCCESSFULLY : 'Review Updated Successfully',
    BUSINESS_ID : 'Business ID',
    OWNER_ID : 'Owner ID',
    CREATION_DATE : 'Creation Date',
    OPTIONS : 'Options',
    ACTIONS : 'Actions',
    BUSINESS_SUCCESSFULLY_CREATED : 'Business successfully created',
    BUSINESS_SUCCESSFULLY_UPDATED : 'Business successfully updated',
    SERVICE : 'Service',
    DURATION : 'Duration',
    PRICE : 'Price',
    ENGLISH : 'English',
    GERMAN : 'German',
    OFFER_PRICE : 'Price Before Discount',
    MANAGE_SERVICES : 'Manage Service',
    HAS_SUB_CATEGORIES : 'Has sub categories',
    ENTER_A_PROPER_PRICE : 'Enter a proper price',
    BUSINESS_SERVICE_ID : 'Business Service ID',
    SUCCESSFULLY_ADDED : 'Successfully Added',
    SUCCESSFULLY_UPDATED : 'Successfully Updated',
    TITLE : 'Title',
    SELECT_LANGUAGE : 'Select Language',
    INVALID_LANGUAGE_SELECTED : 'Invalid language selected',
    TITLE_SHOWN_TO_USER : 'Title (Shown to User)',
    TITLE_SHOWN_TO_GOOGLE : 'Title (Shown to Google)',
    CHOOSE_BY_DEFAULT : 'Choose by default',
    GALLERY_IMAGES : 'Gallery Images',
    MARK_AS_PRIMARY_IMAGE : 'Mark as primary image',
    PROVIDE_A_VALID_DISTANCE_IN_METERS : 'Provide valid distance in meters',
    WHATSAPP_PHONE_NUMBER : 'Whatsapp Number',
    REJECT : 'Reject',
    ACCEPT : 'Accept',
    REJECTED : 'Rejected',
    DELETED : 'Deleted',
    COPIED : 'Copied',
    IS_AGREEMENT_DONE : 'Is agreement Done / Is working with us ?',
    WORKING_WITH_US : 'Working with us',
    NOTES : 'Notes',
    NOTIFICATIONS : 'Notifications',
    NOTE : 'Note',
    NOTE_ID : 'Note ID',
    CREATED_BY : 'Create By',
    ADD : 'Add',
    UPDATE : 'Update',
    NOTE_ADDED_SUCCESSFULLY : 'Note added successfully',
    NOTE_UPDATED_SUCCESSFULLY : 'Note updated successfully',
    CANCEL : 'Cancel',
    BUSINESS_SUCCESSFULLY_ACTIVATED : 'Business successfully activated',
    BUSINESS_SUCCESSFULLY_PAUSE : 'Business successfully pause',
    BUSINESS_SUCCESSFULLY_REJECTED : 'Business successfully rejected',
    BUSINESS_SUCCESSFULLY_DELETED : 'Business successfully deleted',
    SOMETHING_IS_WRONG : 'Something is wrong',
    AGREEMENT_SUCCESSFULLY_UPDATED : 'Agreement successfully updated',
    MEN_ARE_ALLOWED : 'Men are allowed',
    WOMEN_ARE_ALLOWED : 'Women are allowed',
    COUPLES_ARE_ALLOWED : 'Couples are allowed',
    URL_ALREADY_EXIST : 'Url already exist',
    BUSINESS_HAS_AN_GOVT_LICENSE : 'Business has an authorized Govt license.',
    GIVE_VERIFICATION_BADGE : 'Give verification badge',
    EMPLOYEE_CREATED_SUCCESSFULLY : 'Employee created successfully',
    EMPLOYEE_UPDATED_SUCCESSFULLY : 'Employee updated successfully',
    DATE_OF_BIRTH : 'Date of birth',
    MALE : 'Male',
    FEMALE : 'Female',
    OTHER : 'Other',
    IMAGE : 'Image',
    PUBLIC_IMAGE : 'Public Image',
    DELETE_IMAGE : 'Delete Image',
    ARE_YOU_SURE_TO_DELETE_IMAGE : 'Are you sure to delete this image ?',
    UPDATED_BY : 'Update By',
    OWNER_NAME : 'Owner Name',
    WORKING_STATUS : 'Working Status',
    WORKING : 'Working',
    NOT_WORKING : 'Not Working',
    AGREEMENT_STATUS : 'Agreement Status',
    AGREEMENT_DONE : 'Agreement Done',
    PLEASE_USE_ANOTHER_URL : 'Please use another url',
    NAME : 'Name',
    AGE : 'Age',
    YEARS_OLD : 'years old',
    BUSINESS_NOT_CREATED_TECHNICAL_ERROR : 'Business not created successfully. Technical Error.',
    BUSINESS_NOT_UPDATED_TECHNICAL_ERROR : 'Business not updated successfully. Technical Error.',
    CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR : 'Cannot fetched businesses. Technical Error.',
    AGREEMENT_NOT_UPDATED_TECHNICAL_ERROR : 'Agreement not updated successfully. Technical Error.',
    EMPLOYEE_DETAILS_CANNOT_FETCHED : 'Employee details cannot be fetched. Technical Error.',
    IMAGE_DELETED_SUCCESSFULLY : 'Image successfully deleted',
    TIMING_ADDED_SUCCESSFULLY : 'Timing added successfully',
    LANDMARK_ID : 'Landmark ID',
    PRIMARY : 'Primary',
    PRIMARY_LANDMARK : 'Primary Landmark',
    EDIT : 'Edit',
    EDIT_LANDMARK : 'Edit Landmark',
    OVERALL_REVIEW : 'Overall Review',
    ADD_REVIEW : 'Add Review',
    SAVE_OVERALL_REVIEW : 'Save Overall Review',
    SAVE_REVIEW : 'Save Review',
    REVIEWER : 'Reviewer',
    SAVE_BUSINESS_SERVICE : 'Save Business Service',
    BUSINESS_SERVICES : 'Business Services',
    AED : 'AED',
    ADD_SUB_CATEGORY : 'Add Sub Category',
    SUB_CATEGORY_ID : 'Sub Category Id',
    ID : 'ID',
    CREATED_ON : "Created On",
    DUPLICATE_ENTRY_FOR_SERVICE : "Duplicate entry for service",
    BUSINESS_SERVICE_ADDED_SUCCESSFULLY : "Business Service added successfully",
    BUSINESS_SERVICE_UPDATED_SUCCESSFULLY : "Business Service updated successfully",
    SERVICE_ALREADY_EXIST_FOR_BUSINESS : "Service already exist for business",
    MUST_BE_GRATER_THEN : " must be greater than ",
    SAVE_SUB_CATEGORY_AND_ADD_MORE : 'Save and Add More',
    TITLE_IS_REQUIRED: 'Title is required',
    DEFAULT : 'Default',
    SELECTED_AS_DEFAULT : "Selected as default",
    SUCCESSFULLY_ADDED_SUB_CATEGORY : "Successfully added sub category",
    SUCCESSFULLY_UPDATED_SUB_CATEGORY : "Successfully updated sub category",
    EDIT_SUB_CATEGORY : 'Edit Sub category',
    NO_PRICE_AND_DURATION : 'No Price & Duration',
    DOCUMENTS : 'Documents',
    UPLOAD_GALLERY_IMAGES : 'Upload Gallery Images',
    UPLOAD_THUMBNAIL_IMAGE : 'Upload Thumbnail Image',
    PREVIEW_IMAGES : "Preview Images",
    PLEASE_ADD_IMAGES_BEFORE_SUBMITTING : "Please add images before submitting",
    FILE_IS_TOO_LARGE : "File Size is too large",
    PLEASE_UPLOAD_AN_IMAGE : "Please upload an jpeg, jpg or png file.",
    PLEASE_SELECT_ONE_IMAGE_AS_PRIMARY : "Please select one image as primary",
    IMAGES_SUCCESSFULLY_ADDED : 'Images successfully added',
    ARE_YOU_SURE_TO_DELETE_THIS_IMAGE : 'Are you sure to delete this image ?',
    CANNOT_DELETE_PRIMARY_IMAGE : "Cannot delete primary image",
    ARE_YOU_SURE_TO_MARK_THIS_IMAGE_AS_PRIMARY : "Are you sure to mark this image as primary image ?",
    MARKED_AS_PRIMARY_SUCCESSFULLY : "Marked as primary successfully.",
    LICENSE_DOCUMENT : "License Document",
    UPLOAD_LICENSE : "Upload License",
    SUCCESSFULLY_UPDATED_LICENSE_DOCUMENT : "Successfully updated license document",
    DELETE_LICENSE : "Delete License",
    ARE_YOU_SURE_TO_DELETE_THIS_LICENSE : "Are you sure to delete this license ?",
    LICENSE_SUCCESSFULLY_DELETED : 'License successfully deleted',
    AGREEMENT_DOCUMENT : 'Agreement document',
    SUCCESSFULLY_UPDATED_AGREEMENT_DOCUMENT : "Successfully updated agreement document",
    DELETE_AGREEMENT : "Delete Agreement",
    ARE_YOU_SURE_TO_DELETE_THIS_AGREEMENT : 'Are you sure to delete this agreement ?',
    EDIT_EMPLOYEE : 'Edit Employee',
    UPDATE_EMPLOYEE : 'Update Employee',
    ADD_NOTE : "Add Note",
    EDIT_NOTE : "Edit Note",
    UPDATE_NOTE : "Update Note",
    BOOKING_ID : 'Booking Id',
    BUSINESS_PHONE : 'Business Phone',
	BUSINESS_ADDRESS : 'Business Address',
	GUEST_NAME : 'Guest Name',
	BOOKING_DATE : 'Booking Date',
	BOOKED_ON : 'Booked On',
	BOOKING_AMOUNT : 'Booking Amount',
	VIEW_BOOKING_DETAILS : 'View Details',
	TOTAL_GUEST : 'Total Guest',
	SERIAL_NUMBER : 'S. No.',
	REQUEST_TYPE : 'Request Type',
	MEETING_TYPE : 'Meeting Type',
	ONLINE_MEETING : 'Online Meeting',
	OFFICE_MEETING : 'Office Meeting',
	CONTACT_REQUEST : 'Contact Request',
	CALLBACK_REQUEST : 'Callback Request',
	ADDRESS : 'Address',
	BOOKED_SERVICE : 'View Services',
	DATE : 'Date',
	TIME : 'Time',
	BUSINESS_DETAILS : 'Business Details',
	GUEST_DETAILS : 'Guest Details',
	BOOKING_DETAILS : 'Booking Details',
    BOOKINGS : "Bookings",
    ACCEPTED : "Accepted",
    RECEIVED : "Received",
    ACTION : "Action",
    BOOKING_STATUS_CHANGED : "Booking status changed successfully",
    BOOKING_ACCEPT_CONFIRMATION : "Are you sure you want to accept this booking ?",
    BOOKING_REJECT_CONFIRMATION : "Are you sure you want to reject this booking ?",
    BOOKING_DELETE_CONFIRMATION : "Are you sure you want to delete this booking ?",
    DELETE_CONFIRMATION : "Are you sure you want to delete this ?",
    READ_CONFIRMATION : "Are you sure you want to read this ?",
    MARK_AS_DONE_CONFIRMATION : "Are you sure you want to mark this as done ?",
    ACTIVE_CONFIRMATION : "Are you sure you want to active ?",
    INACTIVE_CONFIRMATION : "Are you sure you want to inactive ?",
    DISABLED_CONFIRMATION : "Are you sure you want to disable ?",
    NEW_BUSINESS_SIGN_UP_REQUEST_DELETE_CONFIRMATION : "Are you sure you want to delete this business ?",
    NEW_BUSINESS_SIGN_UP_REQUEST_READ_CONFIRMATION : "Are you sure you want to read this business ?",
    CLOSE : "Close",
    FULL_ADDRESS_IMAGE : "Full address image",
    BEFORE_BOOKING_ADDRESS_IMAGE : "Before booking address image",
    FORCE_UPDATE_MAP_IMAGE : "Force update map image",
    EMAIL : 'Email',
    MESSAGE : 'Message',
    READ : 'Read',
    MARK_AS_READ : 'Mark as read',
    MARK_AS_DONE : 'Mark as done',
    YES_COMPLETED : 'Yes Completed',
    DONE : 'Done',
    FEEDBACKS : 'Feedbacks',
    CONTACTS : 'Contacts',
    APPOINTMENTS : 'Appointments',
    IS_READ : 'Is Read',
    IS_DONE : 'Is Done?',
    SELECT_TITLE_SERVICE : 'Select Title Service',
    TITLE_SERVICE : 'Title Service',
    GOOGLE_PLACE_ID : 'Google Place ID',
    SERVICE_META_DATA : 'Service Meta Data',
    SERVICES : 'Services',
    KEYWORD : 'Keyword',
    SELECT_SERVICE : 'Select Service',
    SELECT_BUSINESS_TYPE : 'Select Business Type',
    SELECT_REGION : 'Select Region',
    SELECT_REGION_AND_ONE_OPTION_FROM_BUSINESS_TYPE_OR_SERVICE : 'Select Region and at least one option from Business type or Service',
    SELECT_ONE_OPTION_FROM_BUSINESS_TYPE_OR_SERVICE : 'Select at least one option from Business type or Service',
    NOT_ADDED : 'Not Added',
    BUSINESS_TYPE_AND_SERVICE_CANNOT_BE_SELECTED : 'Business type and Service both cannot be selected together',
    ARE_YOU_SURE_TO_DELETE_SEARCH_META_DATA : 'Are you sure to delete search meta data ?',
    DATA_DELETED_SUCCESSFULLY : 'Data Deleted Successfully ?',
    SAVE_SEARCH_META_DATA : 'Save Search Meta Data',
    UPDATE_SEARCH_META_DATA : 'Update Search Meta Data',
    PLEASE_SELECT_A_BUSINESS_TYPE : 'Please select a business type',
    PLEASE_SELECT_A_REGION : 'Please select a region',
    SEARCH_META_DATA : 'Search Meta Data',
    NO_SPACED_ALLOWED_IN_URL : 'No spaces allowed in url',
    NO_DATA_FOUND : 'No data found',
    IMAGE_TYPE_PRIMARY : 'Primary',
    IMAGE_TYPE_INNER : 'Inside',
    IMAGE_TYPE_OUTSIDE : 'Outside',
    IMAGE_TYPE_RECEPTION : 'Reception', 
    IMAGE_TYPE_MENU_CARD : 'Menu Card',
    IMAGE_TYPE_FRONT : 'Front',
    IMAGE_TYPE_GALLERY : 'Gallery',
    IMAGE_TYPE : 'Image Type',
    SELECT_IMAGE_TYPE : 'Select Image Type',
    CHANGE_IMAGE_TYPE : 'Change Image Type',
    ARE_YOU_SURE_TO_CHANGE_IMAGE_TYPE : 'Are you sure to change image type ?',
    IMAGE_TYPE_SUCCESSFULLY_CHANGED : 'Image Type Successfully changed.',
    PLEASE_SELECT_AN_OPTION_FOR_THIS_IMAGE : 'Please select an option for this image.',
    SEARCH_META_DATA_TITLE : 'Title ( Shown to user )',
    SEARCH_META_DATA_META_TITLE : 'Meta Title ( Shown to google )',
    SERVICE_SHORT_DESCRIPTION : 'Short Description (Shown on Search Page)',
    SERVICE_DESCRIPTION : 'Description (Shown on Business Page)',
    MAX_650_CHARACTERS : 'Max 650 Characters',
    MAX_1000_CHARACTERS : 'Max 1000 Characters',
    MAX_190_CHARACTERS : 'Max 190 Characters',
    SAVE_SERVICE : 'Save Service',
    CANNOT_LOAD_SERVICES_SOME_TECHNICAL_ERROR : 'Cannot load services. Some technical error.',
    URL_IS_REQUIRED : 'Url is required',
    DESCRIPTION_IS_REQUIRED : 'Description is required', 
    CANNOT_CREATE_NEW_SERVICE : 'New services cannot be created.', 
    SERVICE_SUCCESSFULLY_UPDATED : 'Service successfully updated',
    SERVICE_SUCCESSFULLY_CREATED : 'Service successfully created',
    UPDATE_SERVICE : 'Update Service',
    NO_SERVICE_FOUND : 'No service found',
    SOME_TECHNICAL_ERROR_PLEASE_CALL_BACKEND_TEAM : 'Some technical error occur. Please inform backend team.',
    MANAGE_REGION : 'Manage Region',
    REGIONS : 'Regions',
    CANNOT_CREATE_NEW_REGION : 'Cannot create a new region.',
    UPDATE_REGION : 'Update Region',
    SAVE_REGION : 'Save Region',
    SUCCESSFULLY_UPDATED_REGION : 'Successfully updated Region.',
    SUCCESSFULLY_CREATED_REGION : 'Successfully created Region.',
    NO_REGION_FOUND : 'No region found',
    ARTICLES : 'Articles',
    PAGE_NAME : 'Page Name',
    PAGE_URL : 'Page URL',
    EDIT_ARTICLE : 'Edit Article',
    ADD_ARTICLE : 'Add Article',
    ALL_ARTICLES : 'All Articles',
    CANNOT_LOAD_ARTICLE_TECHNICAL_ERROR : 'Cannot fetched article. Technical Error.',
    ADD_NEW_ARTICLE : 'Add new article',
    FRONT_IMAGE : 'Front Image',
    PICTURE_OF_PRICE_LIST : 'Picture of Price List',
    EMPLOYEE_IMAGES : 'Employee Images',
    BUSINESS_SEARCH_DETAILS : 'Business Search Details',
    SAVE_BUSINESS_SEARCH_DETAILS : 'Save Business Search Details',
    GOOGLE_DETAILS : 'Google Details',
    SEARCH_META_DATA_TOP_TITLE : 'Title (Top Title)',
    SEARCH_META_DATA_BOTTOM_TITLE : 'Title (Description Title)',
    ARTICLE_CREATED_SUCCESSFULLY : 'Article created successfully',
    ARTICLE_UPDATED_SUCCESSFULLY : 'Article updated successfully',
    ARTICLE_NOT_CREATED_TECHNICAL_ERROR : 'Article not created successfully. Technical Error.',
    ARTICLE_NOT_UPDATED_TECHNICAL_ERROR : 'Article not updated successfully. Technical Error.',
    ACTIVE_ARTICLE_CONFIRMATION : 'Are you sure you want to activate this article?',
    INACTIVE_ARTICLE_CONFIRMATION : 'Are you sure you want to deactivate this article?',
    ACTIVATED_SUCCESSFULLY :'Activated successfully',
    DEACTIVATED_SUCCESSFULLY :'Deactivated successfully',
    CANNOT_CHANGE_STATUS_TECHNICAL_ERROR : 'Cannot change status. Technical Error.',
    CREATE_ARTICLE : 'Create Article',
    SEND_BOOKING_NOTIFICATION : 'Send booking notification',
    SEND_WHATSAPP_NOTIFICATION : 'Send whatsapp notification',
    WHATSAPP_PHONE_NUMBER_FOR_NOTIFICATION : 'Whatsapp number for notification',
    SAVE_BUSINESS_NOTIFICATION_DETAILS : 'Save Notifications Details',
    WELLNESS_SIGN_UP_CENTER : 'Wellness Center Sign Up',
    CONTACT_PERSON_NAME: 'Contact Person Name',
    MANAGE_BUSINESS_TYPE: 'Manage business type',
    BUSINESS_TYPE_NAV : 'Business Type',
    SAVE_BUSINESS_TYPE : 'Save Business Type',
    UPDATE_BUSINESS_TYPE : 'Update Business Type',
    BUSINESS_TYPES : 'Business Types',
    NO_BUSINESS_TYPE_FOUND : 'No business type found',
    BUSINESS_TYPE_NAME : 'Business type name',
    SUCCESSFULLY_UPDATED_BUSINESS_TYPE : 'Successfully updated business type.',
    SUCCESSFULLY_CREATED_BUSINESS_TYPE : 'Successfully created business type.',
    LIST_BUSINESS: 'List Business',
    INVALID_EMAIL_OR_PASSWORD: 'Invalid email or password',
    DASHBOARD: 'Dashboard',
    LOGIN: 'Login',
    BUSINESS_EMPLOYEES: 'Business Employees',
    NOTES_ADD: 'Notes Add',
    BUSINESS_NOTIFICATION: 'Business Notification',
    ARTICLE: 'Article',
    MANAGE_IMAGE: 'Manage Image',
    MANAGE_IMAGES: 'Manage Images',
    CANNOT_LOAD_BUSINESSES_IMAGES_TECHNICAL_ERROR : 'Cannot fetched business images. Technical Error.',
    CANNOT_LOAD_EMPLOYEE_IMAGES_TECHNICAL_ERROR : 'Cannot fetched employee images. Technical Error.',
    BUSINESS_IMAGES: 'Business Images',
    SHOW_UNTAG_BUSINESS_IMAGES: 'Show untagged images',
    SHOW_ALL: 'Show all',
    EMPLOYEE_MISSING_PUBLIC_IMAGE : 'Missing public image',
    NOT_DEFINED : 'Not defined',
    REAL_IMAGE : 'Real Image',
    SELECT_COUNTRY : 'Select Country',
    SELECT_AGE : 'Select Age',
    CONTACT_PERSON_DETAILS : 'Contact person details',
    CONTACT_PERSONS_NAME : 'Contact person name',
    CONTACT_PERSON_PHONE_NUMBER : 'Contact person phone number',
    WEBSITE : 'Website',
    ENTER_VALID_WEBSITE_URL : 'Please enter valid website URL',
    MANAGE_BUSINESS: 'Manage Businesses',
    SHOW_WORKING_WITH_US: 'Show working with us',
    REAL_IMAGE_NOT_AVAILABLE: 'Real image not available',
    PUBLIC_IMAGE_NOT_AVAILABLE: 'Public image not available',
    PACKAGE: 'Package',
    EDIT_PACKAGE: 'Edit Package',
    ADD_PACKAGE: 'Add Package',
    PACKAGE_TITLE: 'Add Package',
    SAVE_PACKAGE : 'Save Package',
    SAVE_SUB_CATEGORY : 'Save Sub Category',
    PACKAGE_ADDED_SUCCESSFULLY : "Package added successfully",
    PACKAGE_UPDATED_SUCCESSFULLY : "Package updated successfully",
    PACKAGES : 'Packages',
    ENTER_PACKAGE_NAME : 'Please enter a package name',
    PACKAGE_STATUS_CHANGED : "Package status changed successfully",
    PACKAGE_ACTIVE_CONFIRMATION : "Are you sure you want to activate this package ?",
    PACKAGE_INACTIVE_CONFIRMATION : "Are you sure you want to deactivate this package ?",
    PACKAGE_DELETE_CONFIRMATION : "Are you sure you want to delete this package ?",
    CANNOT_LOAD_PACKAGE_TECHNICAL_ERROR : 'Cannot fetched package. Technical Error.',
    SUB_PACKAGE_DELETE_CONFIRMATION : "Are you sure you want to delete this sub category ?",
    READ_MORE : "Read More",
    READ_LESS : "Read Less",
}