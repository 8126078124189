import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import Validator from "../Validator/Validator";
import {  API_URL_DOES_URL_EXIST } from "./UrlConstant";
import UrlIsExistResultPojo from "./UrlPojo/UrlIsExistResultPojo";

export default class UrlService {
    private httpService = new HttpService();
    private validator = new Validator();
    private logger = new Logger();

    async doseUrlExist(url : string, typeId: number) : Promise<UrlIsExistResultPojo> {
        try {
            this.validator.isValidString('url', url);
            this.validator.validatePositiveInteger(typeId);

            const apiEndpoint = `${API_URL_DOES_URL_EXIST}/${url}/${typeId}`;
            const httpResult = await this.httpService.get(apiEndpoint, {});

            return { isSuccess : httpResult.data.isSuccess, typeId : httpResult.data.response.typeId, typeReferenceId : httpResult.data.response.typeReferenceId, doesUrlExist : httpResult.data.response.typeId };
        } catch(e) {
            this.logger.error(e);
            throw e;
        }
    }
    
}