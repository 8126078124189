import axios from "axios"
import Logger from "../Logger/Logger";
import StorageServices from "../Storage/StorageServices";
import Validator from "../Validator/Validator";
import { HttpResultPojo } from "./HttpResultPojo";
export default class HttpService {
    private authorizationToken;
    private validator = new Validator();
    private logger = new Logger();;
    constructor(){
        const storageServices = new StorageServices();
        const userToken = storageServices.get('user');
        this.authorizationToken = null;
        if(userToken !== undefined && userToken !== null){
            this.authorizationToken = JSON.parse(userToken).token;
        }
    }
    private isRequestSuccessful(status : number) : boolean {
        return (status >= 200 && status <= 299) ? true : false;
    }
    async get(apiEndpoint: string, params: object) : Promise<HttpResultPojo>{
        try{
            this.validator.isValidString('apiEndpoint', apiEndpoint);
            const result = await axios.get(apiEndpoint, { params, headers : { Authorization : `Bearer ${this.authorizationToken}` } });
            return { data : result.data, status : result.status, isRequestSuccessful : this.isRequestSuccessful(result.status) }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async post(apiEndpoint: string, params: object) : Promise<HttpResultPojo>{
        try{
            this.validator.isValidString('apiEndpoint', apiEndpoint);
            const result = await axios.post(apiEndpoint, params, { headers : { Authorization : `Bearer ${this.authorizationToken}` } });
            return { data : result.data, status : result.status, isRequestSuccessful : this.isRequestSuccessful(result.status) }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async postFormData(apiEndpoint: string, formData: any) : Promise<HttpResultPojo>{
        try{
            this.validator.isValidString('apiEndpoint', apiEndpoint);
            const result = await axios.post(apiEndpoint, formData, { headers : { 'content-type': 'multipart/form-data', Authorization : `Bearer ${this.authorizationToken}` } });
            return { data : result.data, status : result.status, isRequestSuccessful : this.isRequestSuccessful(result.status) }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async put(apiEndpoint: string, params: object) : Promise<HttpResultPojo>{
        try{
            this.validator.isValidString('apiEndpoint', apiEndpoint);
            const result = await axios.put(apiEndpoint, params, { headers : { Authorization : `Bearer ${this.authorizationToken}` } });
            return { data : result.data, status : result.status, isRequestSuccessful : this.isRequestSuccessful(result.status) }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async delete(apiEndpoint: string, params: object) : Promise<HttpResultPojo>{
        try{
            this.validator.isValidString('apiEndpoint', apiEndpoint); 
            const result = await axios.delete(apiEndpoint, { headers: { Authorization : `Bearer ${this.authorizationToken}` }, params} );
            return { data : result.data, status : result.status, isRequestSuccessful : this.isRequestSuccessful(result.status) }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}