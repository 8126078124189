import { Language } from "../../language/English";
import {CONTACT_STATUS_DELETED, CONTACT_IS_READ_ACTIVE, CONTACT_DELETE_TYPE, CONTACT_READ_TYPE, TYPE_CONTACT } from "../../services/Contact/ContactConstant";
import { ContactPojo } from "../../services/Contact/ContactPojo/ContactPojo";

import { CButton} from '@coreui/react';
import Loader from "../../components/Layout/Loader";
import ConfirmationBoxModal from "../../components/Layout/ConfirmationBoxModal";
import {useState, useEffect} from 'react';
import { ContactService } from "../../services/Contact/ContactService";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import CIcon from "@coreui/icons-react";
import {cilCheckCircle, cilCopy, cilEnvelopeOpen, cilPhone } from "@coreui/icons";
import ReadMoreText from '../ReadMoreText/ReadMoreText';

const IndividualContact = (props : {contacts :ContactPojo,  key : number, count : number}) => {
    const contacts = props.contacts;
    
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [contactStatus, setContactStatus] = useState<number>();
    const [showAction, setShowAction] = useState<boolean>(true);
    const [confirmationBoxShow, setConfirmationBoxShow] = useState(false);
    const [deleteConfirmationBoxShow, setDeleteConfirmationBoxShow] = useState(false);


    const handleConfirmation = (status : number) =>
    {
        setConfirmationBoxShow(!confirmationBoxShow);
    }

    const deleteHandleConfirmation = (status : number) =>
    {
        setDeleteConfirmationBoxShow(!deleteConfirmationBoxShow);
    }
    
    const deleteContact = (contactId: number) =>
    {
        changeStatusClick(contactId, CONTACT_STATUS_DELETED, CONTACT_DELETE_TYPE);
    }

    const readContact = (contactId: number) =>
    {
        changeStatusClick(contactId, CONTACT_IS_READ_ACTIVE, CONTACT_READ_TYPE);
    }

    const notification = new Notification();
    const logger = new Logger();

    const changeStatusClick = async(contactId: number, status: number, type : string) =>
    {
        setShowLoader(true);
        try{
            const contactService = new ContactService();
            let response: any;
            if (type === CONTACT_DELETE_TYPE) {
                response = await contactService.delete(contactId, status);
            } else if(type === CONTACT_READ_TYPE) {
                response = await contactService.read(contactId, status);
            }
            if(response){
                if (type === CONTACT_DELETE_TYPE) {
                    notification.success(Language.DELETED);
                    setContactStatus(status);
                } else if(type === CONTACT_READ_TYPE) {
                    notification.success(Language.MARK_AS_DONE);
                    setShowAction(false);
                    setContactStatus(status);
                }
            } else {
                notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
            }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const handleCopyClick = (data: any) => {
        navigator.clipboard.writeText(data);
        notification.success(Language.COPIED);
    };

    useEffect(() => {

        const handleDisplay = () =>
        {
            if(contacts.isRead)
            {
                setShowAction(false);
            }
        }

        handleDisplay();
    }, [contacts.isRead]);

    return (
        <>
        { showLoader ? <Loader /> : null }
        {
            contactStatus === CONTACT_STATUS_DELETED ? null :

            <ul key={contacts.contactId} className="business-list-row" >
                <div className="close-button" onClick={() => deleteHandleConfirmation(CONTACT_STATUS_DELETED)}>
                    <span>X</span>
                    {
                        deleteConfirmationBoxShow ? 
                        <ConfirmationBoxModal 
                            title={contacts.name} 
                            message={Language.DELETE_CONFIRMATION} 
                            buttonOneText={Language.YES} 
                            buttonTwoText={Language.CLOSE} 
                            buttonOneCallable={deleteContact} 
                            buttonTwoCallable={deleteHandleConfirmation} 
                            buttonOneCallBackFunctionParams={contacts.contactId}
                            buttonTwoCallBackFunctionParams = {null} />
                        : null
                    }
                </div>
                <div className="done-mark">
                    {
                        !showAction ? <div className="show-action"><CIcon className="read-icon" icon={cilCheckCircle}  /> {Language.DONE}</div> : ''
                    }
                </div>  
                <li className="serial-number" data-label={Language.SERIAL_NUMBER}>{props.count + 1}</li>
                <li data-label={Language.REQUEST_TYPE}>
                    {
                        contacts.type === TYPE_CONTACT ? <><CIcon className="read-icon" icon={cilEnvelopeOpen}  /> {Language.CONTACT_REQUEST}</> : <><CIcon className="read-icon" icon={cilPhone}  /> {Language.CALLBACK_REQUEST}</>
                    }
                </li>
                <li data-label={Language.NAME}>{contacts.name}</li>
                <li data-label={Language.EMAIL}>
                    <a href={`mailto:${contacts.email}`}>{contacts.email}</a> 
                    <CIcon className="read-icon copy-icon" title="Click to copy" icon={cilCopy}  onClick={() => handleCopyClick(contacts.email)} />           
                </li>
                <li data-label={Language.PHONE_NUMBER}>
                    <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
                    <CIcon className="read-icon copy-icon" title="Click to copy" icon={cilCopy}  onClick={() => handleCopyClick(contacts.phone)} />           
                </li>
                <li data-label={Language.CREATED_ON}>{contacts.createdOn}</li>
                <li>
                    <div className="show-action">
                        {
                            contacts.message ? <ReadMoreText text={contacts.message} maxLength={100} /> : 'N/A'
                        }
                    </div>
                </li>
                <li>
                    {
                        showAction ? 
                            <>
                                <CButton className="show-action" size="sm" variant="outline" color="primary" onClick={() => handleConfirmation(CONTACT_IS_READ_ACTIVE)}>
                                    {Language.MARK_AS_DONE}
                                </CButton>                               
                                {
                                    confirmationBoxShow ? 
                                    <ConfirmationBoxModal 
                                        title={contacts.name} 
                                        message={Language.MARK_AS_DONE_CONFIRMATION} 
                                        buttonOneText={Language.YES} 
                                        buttonTwoText={Language.CLOSE} 
                                        buttonOneCallable={readContact} 
                                        buttonTwoCallable={handleConfirmation} 
                                        buttonOneCallBackFunctionParams={contacts.contactId}
                                        buttonTwoCallBackFunctionParams = {null} />
                                    : null
                                }
                            </>
                            : <></>
                    }
                    
                </li>
            </ul>
        }
        
        </>
    )
}
export default IndividualContact;