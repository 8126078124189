import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

import { AppBreadcrumb } from './AppBreadcrumb'
import { AppHeaderDropdown } from './AppHeaderDropdown'
import { PAGE_LOGIN } from '../../PagesNames'
import { useSelector } from 'react-redux'
import { selectAuth } from '../../features/authSlice'
import { USER_TYPE_ADMIN, USER_TYPE_SALES } from '../../Constant'

interface IProps {
  sidebarShow: boolean,
  setSidebarShow: (status: boolean) => void
}

export const AppHeader: React.FC<IProps> = ({
  setSidebarShow,
  sidebarShow
}) => {
  const navigate = useNavigate();
  const user = useSelector(selectAuth);
  
  useEffect(() => {
    if (!user.token && user.type !== USER_TYPE_ADMIN && user.type !== USER_TYPE_SALES) {
      navigate(PAGE_LOGIN);
    }
  }, [navigate, user.token, user.type]);
  
  return (
    <CHeader position="sticky" className="mb-2">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => setSidebarShow(!sidebarShow)}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none">
          {/* <CIcon icon={logo} height={48} title="Logo" /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}