// import { cilDelete } from "@coreui/icons";
// import CIcon from "@coreui/icons-react";
const ArticleImageCard = (props : { imageUrl : string }) => {
    return(
        <div className="card" >
            <img src={props.imageUrl} className="card-img-top" alt="..." />
            {/* <span className="delete_image" onClick={() => props.deleteImage()}>
                <CIcon icon={cilDelete} />
            </span> */}
        </div>
    )
}
export default ArticleImageCard;