import { API_GET_ALL_APPOINTMENT, API_DELETE_APPOINTMENT, API_READ_APPOINTMENT} from './AppointmentConstant';
import HttpService from "../Http/HttpService";
import Logger from "../Logger/Logger";
import AppointmentResultsPojo from "./AppointmentPojo/AppointmentResultsPojo";
import Validator from "../Validator/Validator";

export class AppointmentService{
    
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async getAll(): Promise<AppointmentResultsPojo>{
        try{
            const httpResult =  await this.httpService.get(API_GET_ALL_APPOINTMENT, {});  
            return { isSuccess : httpResult.data.isSuccess, appointments : httpResult.data.response }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }    

    async delete(appointmentId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(appointmentId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.delete(API_DELETE_APPOINTMENT, {appointmentId : appointmentId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async read(appointmentId: number, status: number): Promise<Boolean>
    {
        try
        {
            this.validator.validatePositiveInteger(appointmentId);
            this.validator.validateIntegerIncludingZero(status);
            
            const httpResult = await this.httpService.put(API_READ_APPOINTMENT, {appointmentId : appointmentId, status : status});
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}