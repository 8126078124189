import { BASE_URL } from "../../Constant";

export const API_GET_ALL_CONTACT: string = `${BASE_URL}/contact`;
export const API_DELETE_CONTACT: string = `${BASE_URL}/contact`;
export const API_READ_CONTACT: string = `${BASE_URL}/contact/markAsRead`;

export const CONTACT_STATUS_ACTIVE : number = 1;
export const CONTACT_STATUS_DELETED : number = 10;
export const CONTACT_IS_READ_ACTIVE : number = 1;
export const CONTACT_IS_READ_INACTIVE : number = 0;
export const CONTACT_DELETE_TYPE : string = 'delete';
export const CONTACT_READ_TYPE : string = 'read';

export const TYPE_CONTACT : number = 1;
export const TYPE_CALLBACK : number = 2;