import CIcon from "@coreui/icons-react"
import { cilCalendar, cilChatBubble, cilDescription,} from "@coreui/icons"
import { CNavItem } from "@coreui/react"
import { INavigation } from "../../types"
import { PAGE_APPOINTMENTS, PAGE_ARTICLES, PAGE_CONTACTS } from "../../PagesNames"
import { Language } from "../../language/English"
import { USER_TYPE_ADMIN, USER_TYPE_SALES } from "../../Constant"

const commonPermissions = [
  Language.APPOINTMENTS,
  Language.CONTACTS,
  Language.ARTICLES,
]; 

export const NavigationPermissions: any = {
  [USER_TYPE_ADMIN]: [
    ...commonPermissions,
  ],
  [USER_TYPE_SALES]: [
    ...commonPermissions
  ]
};


export const Navigation: INavigation[] = [ 
  {
    component: CNavItem,
    name: Language.APPOINTMENTS,
    to: PAGE_APPOINTMENTS,
    icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.CONTACTS,
    to: PAGE_CONTACTS,
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.ARTICLES,
    to: PAGE_ARTICLES,
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  }
];