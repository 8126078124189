import { BASE_URL } from "../../Constant";

export const API_GET_ALL_APPOINTMENT: string = `${BASE_URL}/appointment`;
export const API_DELETE_APPOINTMENT: string = `${BASE_URL}/appointment`;
export const API_READ_APPOINTMENT: string = `${BASE_URL}/appointment/markAsRead`;

export const APPOINTMENT_STATUS_ACTIVE : number = 1;
export const APPOINTMENT_STATUS_DELETED : number = 10;
export const APPOINTMENT_IS_READ_ACTIVE : number = 1;
export const APPOINTMENT_IS_READ_INACTIVE : number = 0;
export const APPOINTMENT_DELETE_TYPE : string = 'delete';
export const APPOINTMENT_READ_TYPE : string = 'read';

export const MEETING_TYPE_ONLINE : number = 1;
export const MEETING_TYPE_OFFICE : number = 2;