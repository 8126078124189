import { Layout } from "../../components/Layout";
import { useEffect, useState } from "react";
import { AppointmentService } from "../../services/Appointments/AppointmentService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import { AppointmentPojo } from "../../services/Appointments/AppointmentPojo/AppointmentPojo";
import IndividualAppointment from "./IndividualAppointment";
import AllAppointment from "./AllAppointment";

const Appointment = () => {
    const [appointments, setAppointments] = useState<AppointmentPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    
    useEffect(() => {
        const notification = new Notification();
        const logger = new Logger();
        const fetchALLData = async() => {
            try{
                const appointmentService = new AppointmentService();
                const response = await appointmentService.getAll();
                if(response){
                    setAppointments(response.appointments);
                } else {
                    notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
                }
                setShowLoader(false);
            } catch (e){
                notification.error(Language.SOMETHING_IS_WRONG);
                setShowLoader(false);
                logger.error(e);
            }
        }
        setShowLoader(true);
        fetchALLData();
    }, []);
    return (
        <div>
            <Layout>
                { showLoader ? <Loader /> : null }
                <AllAppointment>
                {
                    appointments.length > 0 && appointments.map( (appointments, index) => (
                        <IndividualAppointment appointments={appointments} key={appointments.appointmentId} count={index}/>
                    ))
                }
                </AllAppointment>
        </Layout>
      </div>
    )
}
export default Appointment;