export const BASE_URL: string = 'https://api.etp-enterprise.ch'
//  export const BASE_URL: string = 'http://localhost:8000';

export const API_LOGIN: string = '/api/login'
export const TOKEN_NAME : string = "user"
export const TINYMCE_EDITOR_API_KEY : string = "2jzlctjdwhwr5k92jjnw6t71w1qghc7xbk6diuglo76553ml"

export const USER_TYPE_ADMIN : number = 1; 
export const USER_TYPE_SALES : number = 2; 
export const USER_TYPE_BUSINESS_OWNER : number = 3; 
export const USER_TYPE_GUEST : number = 4;

export const URL_TYPE_ID_ARTICLE : number = 1;

export const LANGUAGE_ENGLISH : number = 1;
export const LANGUAGE_GERMAN : number = 2;

export const URL_TYPE_ARTICLE : number = 1;

export const API_EDITOR_FILE_UPLOAD: string = BASE_URL + '/api/editorFileUpload';
