import * as yup from "yup";
import { Language } from "../../language/English";
import { INPUT_MIN_LENGTH, META_DESCRIPTION_MAX_LENGTH, META_KEYWORD_MAX_LENGTH, NAME_MAX_LENGTH, SHORT_DESCRIPTION_MAX_LENGTH, URL_MAX_LENGTH, URL_MIN_LENGTH } from "./ArticleConstant";
import { LANGUAGE_ENGLISH, LANGUAGE_GERMAN } from "../../Constant";
import { ArticlePojo } from "./ArticlePojo/ArticlePojo";

export const ArticleValidation = (article: ArticlePojo | undefined) => {
    const FILE_SIZE = 543363; // Maximum file size limit in bytes
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
      "image/webp",
    ];

    const schema = yup.object().shape({
        language: yup.number().required().oneOf([LANGUAGE_ENGLISH, LANGUAGE_GERMAN], Language.INVALID_LANGUAGE_SELECTED).label(Language.SELECT_LANGUAGE),
        title: yup.string().trim().min(INPUT_MIN_LENGTH).max(NAME_MAX_LENGTH).required().label(Language.TITLE),
        metaTitle: yup.string().trim().max(NAME_MAX_LENGTH).label(Language.TITLE_SHOWN_TO_GOOGLE),
        url: yup.string().trim().required().matches(/^[A-Za-z0-9-]*$/, "Only alphabets, number and special characters minus are allowed.").min(URL_MIN_LENGTH).max(URL_MAX_LENGTH).label('Url'),
        metaKeyword: yup.string().trim().max(META_KEYWORD_MAX_LENGTH).label(Language.META_KEYWORD),
        metaDescription: yup.string().trim().max(META_DESCRIPTION_MAX_LENGTH).label(Language.META_DESCRIPTION),
        shortDescription: yup.string().trim().max(SHORT_DESCRIPTION_MAX_LENGTH).required().label(Language.SHORT_DESCRIPTION),
        description: yup.string().trim().required().label(Language.DESCRIPTION),
        thumbnail: article ? yup.mixed() : yup.mixed()
            .test('required', 'File is required', value => value && value.length)
            .test(
                "fileSize",
                "File too large",
                value => value?.length > 0 && value?.[0]?.size <= FILE_SIZE
            )
            .test(
                "fileFormat",
                "Unsupported Format",
                value => value && SUPPORTED_FORMATS.includes(value?.[0]?.type)
            ),
    });

    return schema;
} 
