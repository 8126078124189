import { PAGE_DASHBOARD, PAGE_LOGIN, PAGE_CONTACTS, PAGE_APPOINTMENTS, PAGE_ARTICLES, PAGE_SINGLE_ARTICLE } from "./PagesNames"
import { IRoute } from "./types"

import {  Dashboard, Login  } from "./pages"
import Contact from "./pages/Contact/Contact"
import Appointment from "./pages/Appointments/Appointment"
import { Language } from "./language/English"
import { USER_TYPE_ADMIN, USER_TYPE_SALES } from "./Constant"
import Article from "./pages/Article/Article"
import SingleArticle from "./pages/Article/SingleArticle"

const commonPermissions = [
  Language.LOGIN,
  Language.DASHBOARD,
  Language.CONTACTS,
  Language.APPOINTMENTS,
  Language.ARTICLE,
  Language.EDIT_ARTICLE,
  Language.ADD_ARTICLE,
]; 

export const RoutePermissions: any = {
  [USER_TYPE_ADMIN]: [
    ...commonPermissions,
  ],
  [USER_TYPE_SALES]: [
    ...commonPermissions
  ]
};

const commonRoutes = [
  { path: PAGE_LOGIN, name: Language.LOGIN, element: Login, private: false },
]; 

export const defaultRoutes: IRoute[] = [
  ...commonRoutes
]

export const routes: IRoute[] = [
  ...commonRoutes,
  { path: PAGE_DASHBOARD, name: Language.DASHBOARD, element: Dashboard, private: true },
  { path: PAGE_CONTACTS, name: Language.CONTACTS, element: Contact, private: true },
  { path: PAGE_APPOINTMENTS, name: Language.APPOINTMENTS, element: Appointment, private: true },
  { path: PAGE_ARTICLES, name: Language.ARTICLE, element: Article, private: true },
  { path: PAGE_SINGLE_ARTICLE + '/:articleId/edit', name: Language.EDIT_ARTICLE, element: SingleArticle, private: true },
  { path: PAGE_SINGLE_ARTICLE + '/add', name: Language.ADD_ARTICLE, element: SingleArticle, private: true },
]
