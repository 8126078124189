import React, { useState } from 'react';
import { Language } from '../../language/English';

interface ReadMoreTextProps {
  text: string;
  maxLength: number;
}

const ReadMoreText: React.FC<ReadMoreTextProps> = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const truncatedText = isTruncated ? text.slice(0, maxLength) : text;

  return (
    <div>
      {truncatedText.length >= text.length ? truncatedText : truncatedText + '...'}
      {text.length > maxLength && (
        <span className={isTruncated ? 'read-more-btn' : 'read-less-btn'} onClick={toggleTruncate}>
            {isTruncated ? Language.READ_MORE : Language.READ_LESS}
        </span> 
      )}
    </div>
  );
};

export default ReadMoreText;
